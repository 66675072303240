import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  InputGroup,
  Button,
  Input,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import InputMask from "react-input-mask";
//Import Flatepicker
import {
  saveLoanApplicationDetailsAction,
  getLoanApplicationDetailsAction,
} from "../../store/actions";
import DocumentUpload from "../../components/DocumentUpload";
import Person from "../../components/Person";
import LoanApplication from "components/LoanApplication";
const AddEdit = props => {
  const [activeTab, setactiveTab] = useState(1);
  const [personAadhaarNumber, setPersonAadhaarNumber] = useState("");
  const [findPersonEnable, setFindPersonEnable] = useState(true);
  const [passedSteps, setPassedSteps] = useState([1]);
  const history = useHistory();
  const toggleTab = tab => {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 2) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  };
  let initialState = {
    id: props.id,
    name: "",
    phone: "",
    email: "",
    remarks: "",
    docsRef: [],
    bankDetails: {
      bankName: "",
      acName: "",
      acNumber: "",
      IFSC: "",
      branch: "",
      city: "",
    },
    address: {
      curAddress: {
        address1: "",
        address2: "",
        address3: "",
        city: 0,
        state: 0,
        country: 0,
        postalCode: "",
      },
      permanentAddress: {
        address1: "",
        address2: "",
        address3: "",
        city: 0,
        state: 0,
        country: 0,
        postalCode: "",
      },
    },
  };
  const [formData, setFormData] = useState(initialState);
  // useEffect(() => {
  //   if (props.id) {
  //     LoadDealerDeatils(props.id);
  //   }
  // }, [props.id]);
  // const LoadDealerDeatils = id => {
  //   props.setLoading(true);
  //   getLoanApplicationDetailsAction(id)
  //     .then(resp => {
  //       props.setLoading(false);
  //       if (resp.httpCode >= 200 && resp.httpCode <= 299) {
  //         if (resp.data) {
  //           let respdata = resp.data.rows[0];
  //           let fData = JSON.parse(JSON.stringify(initialState));
  //           fData.name = respdata.name;
  //           fData.phone = respdata.phone;
  //           fData.email = respdata.email;
  //           fData.docsRef = respdata.docsRef;
  //           fData.bankDetails = respdata.bankDetails;
  //           fData.address = respdata.address;
  //           fData.remarks = respdata.remarks;
  //           setFormData(fData);
  //         } else {
  //           toast.error("Invalid request");
  //           history.push("/loanfile");
  //         }
  //       } else {
  //         toast.error("Invalid request");
  //         history.push("/loanfile");
  //       }
  //     })
  //     .catch(err => {
  //       toast.error("Invalid request");
  //       history.push("/loanfile");
  //       props.setLoading(false);
  //     });
  // };
  const parentCallBack = (data, type) => {};
  const formatChars = {
    1: "[123456789]",
    0: "[0123456789]",
  };
  const findPersonBtnClick = () => {
    if (
      personAadhaarNumber.length < 14 ||
      personAadhaarNumber.indexOf("-") >= 0
    ) {
      toast.error(`Error! Please enter valid aadhaar Number.`);
    } else {
      setFindPersonEnable(!findPersonEnable);
    }
  };
  return (
    <Container fluid>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">
                Loan Application Details
                <Link
                  style={{ float: "right" }}
                  className="btn btn-danger"
                  to="/loanfile"
                >
                  Back
                </Link>
              </h4>
              <div className="wizard clearfix">
                <div className="steps clearfix">
                  <ul>
                    <NavItem
                      className={classnames({ current: activeTab === 1 })}
                    >
                      <NavLink
                        className={classnames({
                          current: activeTab === 1,
                        })}
                        onClick={() => {
                          setactiveTab(1);
                        }}
                        disabled={!(passedSteps || []).includes(1)}
                      >
                        <span className="number">01</span> Basic Info
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 2 })}
                    >
                      <NavLink
                        className={classnames({
                          active: activeTab === 2,
                        })}
                        onClick={() => {
                          setactiveTab(2);
                        }}
                        //disabled={!(passedSteps || []).includes(2) && !props.id}
                      >
                        <span className="number">02</span> Loan Application
                        Details
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 3 })}
                    >
                      <NavLink
                        className={classnames({
                          active: activeTab === 3,
                        })}
                        onClick={() => {
                          setactiveTab(3);
                        }}
                        // disabled={!(passedSteps || []).includes(3) && !props.id}
                      >
                        <span className="number">03</span> KYC Document Upload
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
                <div className="content clearfix mt-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                      <Container>
                        <Row className="justify-content-md-center">
                          {findPersonEnable ? (
                            <Col md={6} sm={8} xs={12} lg={4}>
                              <Label for="ame" className="form-label">
                                Aadhaar Number *
                              </Label>
                              <InputMask
                                className="form-control show-placegholder"
                                mask="1000 0000 0000"
                                maskChar={"-"}
                                value={personAadhaarNumber}
                                disabled={!findPersonEnable}
                                formatChars={formatChars}
                                onChange={e => {
                                  setPersonAadhaarNumber(e.target.value);
                                }}
                                placeholder="Aadhaar Number"
                              ></InputMask>
                            </Col>
                          ) : (
                            ""
                          )}
                          <Col md={4} sm={12} xs={12} lg={3}>
                            <Button
                              variant="success"
                              className="btn btn-success mt-4"
                              onClick={findPersonBtnClick}
                            >
                              {findPersonEnable
                                ? "Find Person"
                                : "Change Aadhaar Number"}
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                      {!findPersonEnable ? (
                        <Person
                          disabled={false}
                          id=""
                          aadhaarNo={personAadhaarNumber}
                          parentCallBack={parentCallBack}
                        />
                      ) : (
                        ""
                      )}
                    </TabPane>
                    <TabPane tabId={2}>
                      <LoanApplication
                        personId={1}
                        disabled={false}
                        id=""
                        aadhaarNo={personAadhaarNumber}
                        parentCallBack={parentCallBack}
                      />
                    </TabPane>
                    <TabPane tabId={3}>
                      <Row className="pt-1" md={1} xl={1} sm={1} xs={1}>
                        <Col>
                          <h5
                            style={{ borderBottom: "1px solid #ccc" }}
                            className="mt-4 mb-2"
                          >
                            Income Proof
                          </h5>
                          <DocumentUpload
                            underId="tttt"
                            docsRef={[
                              {
                                docTypeId: "",
                                docNumber: "",
                                docRef: "",
                                selectedFile: {},
                                label: "Document Number",
                              },
                            ]}
                            aadhaarNo={personAadhaarNumber}
                            disabled={false}
                            docTypes="income-proof"
                            parentCallBack={parentCallBack}
                            type="APLC"
                          />
                        </Col>
                      </Row>
                      <Row className="pt-5" md={1} xl={1} sm={1} xs={1}>
                        <Col>
                          <h5
                            style={{ borderBottom: "1px solid #ccc" }}
                            className="mt-4 mb-2"
                          >
                            Address Proof
                          </h5>
                          <DocumentUpload
                            underId="ttttt"
                            docsRef={[
                              {
                                docTypeId: "",
                                docNumber: "",
                                docRef: "",
                                selectedFile: {},
                                label: "Document Number",
                              },
                            ]}
                            aadhaarNo={personAadhaarNumber}
                            disabled={false}
                            type="APLC"
                            docTypes="address-proof"
                            parentCallBack={parentCallBack}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-5" md={1} xl={1} sm={1} xs={1}>
                        <Col>
                          <h5
                            style={{ borderBottom: "1px solid #ccc" }}
                            className="mt-4 mb-2"
                          >
                            ID Proof
                          </h5>
                          <DocumentUpload
                            underId="ttttt"
                            docsRef={[
                              {
                                docTypeId: "",
                                docNumber: "",
                                docRef: "",
                                selectedFile: {},
                                label: "Document Number",
                              },
                            ]}
                            aadhaarNo={personAadhaarNumber}
                            disabled={false}
                            type="APLC"
                            docTypes="id-proof"
                            parentCallBack={parentCallBack}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-5" md={1} xl={1} sm={1} xs={1}>
                        <Col>
                          <h5
                            style={{ borderBottom: "1px solid #ccc" }}
                            className="mt-4 mb-2"
                          >
                            Employment Proof
                          </h5>
                          <DocumentUpload
                            underId="tttt"
                            docsRef={[
                              {
                                docTypeId: "",
                                docNumber: "",
                                docRef: "",
                                selectedFile: {},
                                label: "Document Number",
                              },
                            ]}
                            aadhaarNo={personAadhaarNumber}
                            disabled={false}
                            type="APLC"
                            docTypes="employment-proof"
                            parentCallBack={parentCallBack}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-5" md={1} xl={1} sm={1} xs={1}>
                        <Col>
                          <h5
                            style={{ borderBottom: "1px solid #ccc" }}
                            className="mt-4 mb-2"
                          >
                            Others Documnent
                          </h5>
                          <DocumentUpload
                            underId="tttt"
                            docsRef={[]}
                            aadhaarNo={personAadhaarNumber}
                            disabled={false}
                            type="APLC"
                            docTypes="others"
                            parentCallBack={parentCallBack}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default IsLoadingHOC(AddEdit, "Wait .....");
