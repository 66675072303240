import React, { Component } from "react";
import PropTypes from "prop-types";

import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import IsLoadingHOC from "../../components/IsLoadingHOC";

// //Social Media Imports
// import { GoogleLogin } from "react-google-login";
// // import TwitterLogin from "react-twitter-auth"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// //Import config
import { google } from "../../config";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
// actions
import {
  apiError,
  loginUser,
  socialLogin,
  ssoLoginAction,
} from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.jpeg";
import lightlogo from "../../assets/images/logo.jpeg";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.apiError("");
  }
  componentDidUpdate(newProps) {
    if (!newProps.loading && this.props.loading) {
      this.props.setLoading(false);
    }
  }
  googleResponse = resp => {
    this.props.setLoading(true);
    let data = JSON.parse(window.atob(resp.credential.split(".")[1]));
    let requestBody = { token: resp.credential, extraInfo: { ...resp, data } };
    ssoLoginAction(requestBody)
      .then(res => {
        this.props.setLoading(false);
        localStorage.setItem("authUser", JSON.stringify(res.data));
        window.location.href = "/dashboard";
        toast.success("User Login successfully");
      })
      .catch(err => {
        this.props.setLoading(false);
        let msg =
          "Something went wrong! Please try again or contact to Administration Department";
        if (err.response) {
          if (err.response.data) {
            if (Array.isArray(err.response.data.errors)) {
              msg = "";
              for (let i in err.response.data.errors) {
                msg = `${msg}
              ${
                err.response.data.errors[i].error_msg
                  ? err.response.data.errors[i].error_msg
                  : err.response.data.errors[i].message
              }`;
              }
            }
          }
        }
        toast.error(msg);
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to LoanPeCar.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div
                          className="avatar-md profile-user-wid mb-4"
                          style={{ width: "8rem" }}
                        >
                          <span className="avatar-title bg-light">
                            <img src={lightlogo} alt="" height="34" />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div
                          className="avatar-md profile-user-wid mb-4"
                          style={{ width: "8rem" }}
                        >
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={logo} alt="" height="34" />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.props.error && this.props.error ? (
                        <Alert color="danger">{this.props.error}</Alert>
                      ) : null}
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          userName: (this.state && this.state.userName) || "",
                          password: (this.state && this.state.password) || "",
                        }}
                        validationSchema={Yup.object().shape({
                          userName: Yup.string().required(
                            "Please Enter User Name"
                          ),
                          password: Yup.string().required(
                            "Please Enter Valid Password"
                          ),
                        })}
                        onSubmit={values => {
                          this.props.setLoading(true);
                          this.props.loginUser(values, this.props.history);
                        }}
                      >
                        {({ errors, status, touched }) => {
                          return (
                            <Form className="form-horizontal">
                              <div className="mb-3">
                                <Label for="userName" className="form-label">
                                  User Name
                                </Label>
                                <Field
                                  name="userName"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.userName && touched.userName
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="userName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label for="password" className="form-label">
                                  Password
                                </Label>
                                <div className="input-group auth-pass-inputgroup">
                                  <Field
                                    name="password"
                                    type="password"
                                    autoComplete="true"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <button
                                    className="btn btn-light "
                                    type="button"
                                    id="password-addon"
                                  >
                                    <i className="mdi mdi-eye-outline"></i>
                                  </button>
                                </div>
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customControlInline"
                                >
                                  Remember me
                                </label>
                              </div>

                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="submit"
                                >
                                  Log In
                                </button>
                              </div>

                              <div className="mt-4 text-center">
                                <ul className="list-inline">
                                  <li className="list-inline-item">
                                    {google.CLIENT_ID === "" ? (
                                      ""
                                    ) : (
                                      <GoogleOAuthProvider
                                        clientId={google.CLIENT_ID}
                                      >
                                        <GoogleLogin
                                          onSuccess={this.googleResponse}
                                          onError={err => {
                                            console.log(
                                              "err..............",
                                              err
                                            );
                                          }}
                                          useOneTap={true}
                                        />
                                      </GoogleOAuthProvider>
                                    )}
                                  </li>
                                </ul>
                              </div>
                              {/*  <li className="list-inline-item">
                                  <FacebookLogin
                                    appId={facebook.APP_ID}
                                    autoLoad={false}
                                    callback={this.facebookResponse}
                                    render={renderProps => (
                                      <Link
                                        to={""}
                                        className="social-list-item bg-primary text-white border-primary"
                                      >
                                        <i className="mdi mdi-facebook" />
                                      </Link>
                                    )}
                                  />
                                </li>
                                
                              </ul>
                            </div>

                             <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1" /> Forgot your
                                password?
                              </Link>
                            </div> */}
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  {/* <p>
                    Don&apos;t have an account ?
                    <Link to="register" className="fw-medium text-primary">
                      Signup Now
                    </Link>
                  </p> */}
                  <p>© {new Date().getFullYear()} LoanPeCar.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  loading: PropTypes.any,
};

const mapStateToProps = state => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(
    IsLoadingHOC(Login, "Wait .....")
  )
);
