import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  InputGroup,
  Button,
  Input,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import IsLoadingHOC from "./IsLoadingHOC";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import {
  getCountryListAction,
  getStateListAction,
  getCityListAction,
  getLookupListAction,
} from "./../store/actions";
import Select from "react-select";
const Person = props => {
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [curStateList, setCurStateList] = useState([]);
  const [curCityList, setCurCityList] = useState([]);
  const [lockeupData, setLockeupData] = useState({});
  let initialState = {
    id: props.id,
    fname: "",
    lname: "",
    motherName: "",
    fatherName: "",
    maritalStatus: "",
    education: { stream: "", degree: "" },
    aadhaar: {
      number: props.aadhaarNo,
      docRef: "",
    },
    pan: {
      number: "",
      docRef: "",
    },
    phone: "",
    altPhone: "",
    email: "",
    currentAddress: {
      address1: "",
      address2: "",
      cityId: "",
      stateId: "",
      countryId: "",
      postalCode: "",
    },
    permAddress: {
      address1: "",
      address2: "",
      cityId: "",
      stateId: "",
      countryId: "",
      postalCode: "",
    },
    remarks: "",
    status: 1,
    code: "",
    dependents: 0,
    noOfYearsLeavingInCurAddress: 1,
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    if (props.id) {
      LoadPersonDeatils(props.id);
    }
  }, [props.id]);
  const LoadPersonDeatils = id => {
    // props.setLoading(true);
    // getLoanApplicationDetailsAction(id)
    //   .then(resp => {
    //     props.setLoading(false);
    //     if (resp.httpCode >= 200 && resp.httpCode <= 299) {
    //       if (resp.data) {
    //         let respdata = resp.data.rows[0];
    //         let fData = JSON.parse(JSON.stringify(initialState));
    //         fData.name = respdata.name;
    //         fData.phone = respdata.phone;
    //         fData.email = respdata.email;
    //         fData.docsRef = respdata.docsRef;
    //         fData.bankDetails = respdata.bankDetails;
    //         fData.address = respdata.address;
    //         fData.remarks = respdata.remarks;
    //         setFormData(fData);
    //       } else {
    //         toast.error("Invalid request");
    //         history.push("/loanfile");
    //       }
    //     } else {
    //       toast.error("Invalid request");
    //       history.push("/loanfile");
    //     }
    //   })
    //   .catch(err => {
    //     toast.error("Invalid request");
    //     history.push("/loanfile");
    //     props.setLoading(false);
    //   });
  };
  useEffect(() => {
    loadCountryList();
    loadLoockupData();
  }, []);
  const loadLoockupData = () => {
    props.setLoading(true);
    setLockeupData({});
    getLookupListAction("education,marital-status")
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            if (Array.isArray(resp.data.rows)) {
              let lookupData = {};
              for (let i in resp.data.rows) {
                let tages = resp.data.rows[i].tag;
                if (tages) {
                  for (let j in tages) {
                    if (!lookupData.hasOwnProperty(tages[j])) {
                      lookupData[tages[j]] = [];
                    }
                    lookupData[tages[j]].push(resp.data.rows[i]);
                  }
                }
              }
              setLockeupData(lookupData);
            }
          }
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
  };
  const loadCountryList = () => {
    props.setLoading(true);
    setCountryList([]);
    getCountryListAction()
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let data = [];
            if (Array.isArray(resp.data.rows)) {
              data = resp.data.rows;
            } else if (Array.isArray(resp.data)) {
              data = resp.data;
            }
            setCountryList(
              data.map((v, i) => ({
                value: v.ID,
                label: `${v.CountryName}(${v.AlphaCode3})`,
              }))
            );
          }
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
  };
  useEffect(() => {
    loadStateList("current");
  }, [formData.currentAddress.countryId]);
  useEffect(() => {
    loadStateList("permanent");
  }, [formData.permAddress.countryId]);
  const loadStateList = type => {
    let cuntryId = null;
    props.setLoading(true);
    if (type === "current") {
      setCurStateList([]);
      setCurCityList([]);
      cuntryId = formData.currentAddress.countryId;
    } else {
      setStateList([]);
      setCityList([]);
      cuntryId = formData.permAddress.countryId;
    }

    if (cuntryId) {
      getStateListAction(cuntryId)
        .then(resp => {
          props.setLoading(false);
          if (resp.httpCode >= 200 && resp.httpCode <= 299) {
            if (resp.data) {
              let data = [];
              if (Array.isArray(resp.data.rows)) {
                data = resp.data.rows;
              } else if (Array.isArray(resp.data)) {
                data = resp.data;
              }
              if (type === "current") {
                setCurStateList(
                  data.map((v, i) => ({
                    value: v.ID,
                    label: v.StateName,
                  }))
                );
              } else {
                setStateList(
                  data.map((v, i) => ({
                    value: v.ID,
                    label: v.StateName,
                  }))
                );
              }
            }
          }
        })
        .catch(err => {
          props.setLoading(false);
        });
    }
  };
  useEffect(() => {
    loadCityList("current");
  }, [formData.currentAddress.stateId]);
  useEffect(() => {
    loadCityList("permanent");
  }, [formData.permAddress.stateId]);

  const loadCityList = type => {
    props.setLoading(true);
    let stateId = null;
    if (type === "current") {
      setCurCityList([]);
      stateId = formData.currentAddress.stateId;
    } else {
      setCityList([]);
      stateId = formData.permAddress.stateId;
    }
    if (stateId) {
      getCityListAction(stateId)
        .then(resp => {
          props.setLoading(false);
          if (resp.httpCode >= 200 && resp.httpCode <= 299) {
            if (resp.data) {
              let data = [];
              if (Array.isArray(resp.data.rows)) {
                data = resp.data.rows;
              } else if (Array.isArray(resp.data)) {
                data = resp.data;
              }
              if (type === "current") {
                setCurCityList(
                  data.map((v, i) => ({
                    value: v.ID,
                    label: v.CityName,
                  }))
                );
              } else {
                setCityList(
                  data.map((v, i) => ({
                    value: v.ID,
                    label: v.CityName,
                  }))
                );
              }
            }
          }
        })
        .catch(err => {
          props.setLoading(false);
        });
    }
  };
  const handleChange = (name, value, parentKey = null, childInd = -1) => {
    let fData = JSON.parse(JSON.stringify(formData));
    if (parentKey) {
      if (childInd < 0) {
        fData[parentKey][name] = value;
        if (parentKey === "permAddress" || parentKey === "currentAddress") {
          if (name === "countryId") {
            fData[parentKey].stateId = 0;
            fData[parentKey].cityId = 0;
          } else if (name === "stateId") {
            fData[parentKey].cityId = 0;
          }
        }
      } else {
        fData[parentKey][childInd][name] = value;
      }
    } else {
      fData[name] = value;
    }
    console.log(".........", fData);
    setFormData(fData);
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const handleFormSubmit = () => {
    if (formData.fname.length < 3) {
      toast.error(`Please enter a Valid name`);
      return false;
    } else if (formData.email.length < 3 || !ValidateEmail(formData.email)) {
      toast.error(`Please enter a Valid Email address`);
      return false;
    } else if (
      formData.phone.length < 9 ||
      formData.phone.length > 11 ||
      !phoneRegExp.test(formData.phone)
    ) {
      toast.error(`Please enter a Valid Phone number`);
      return false;
    } else if (!formData.address.countryId) {
      toast.error(`Please select Dealer Country`);
      return false;
    } else if (!formData.address.stateId) {
      toast.error(`Please select Dealer State`);
      return false;
    } else if (!formData.address.cityId) {
      toast.error(`Please select Dealer City`);
      return false;
    } else if (formData.address.postalCode.length !== 6) {
      toast.error(`Please enter a Valid Postal Code`);
      return false;
    } else if (formData.address.address1.length < 2) {
      toast.error(`Please enter a Valid Address`);
      return false;
    }
    let errorStatus = false;
    for (let i in formData.contactPerson) {
      if (formData.contactPerson[i].name.length < 1) {
        toast.error(`Please enter Contact Person Name`);
        errorStatus = true;
      }
      if (
        formData.contactPerson[i].email.length < 3 ||
        !ValidateEmail(formData.contactPerson[i].email)
      ) {
        toast.error(`Please enter Contact Person EmailID`);
        errorStatus = true;
      }
      if (formData.contactPerson[i].phone.length < 9) {
        toast.error(`Please enter Contact Person valid Phone Number`);
        errorStatus = true;
      }
    }
    props.setLoading(true);
    let requestBody = JSON.parse(JSON.stringify(formData));
    if (!requestBody.id) {
      delete requestBody.id;
    }
    delete requestBody.docsRef;
    saveLoanApplicationDetailsAction(
      formData.id ? "put" : "post",
      requestBody,
      formData.id
    )
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          toast.success("Information saved successfully");
          setFormData(initialState);
          //history.push("/loanfile");
        } else {
          toast.error(
            "Something went wrong! Please try again or contact to Administration Department"
          );
        }
      })
      .catch(err => {
        props.setLoading(false);
        let msg =
          "Something went wrong! Please try again or contact to Administration Department";
        if (err.response) {
          if (err.response.data) {
            if (Array.isArray(err.response.data.errors)) {
              msg = "";
              for (let i in err.response.data.errors) {
                msg = `${msg}
              ${
                err.response.data.errors[i].error_msg
                  ? err.response.data.errors[i].error_msg
                  : err.response.data.errors[i].message
              }`;
              }
            }
          }
        }
        toast.error(msg);
      });
  };
  const ValidateEmail = mail => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };
  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Person Details</h4>
            <div className="content clearfix mt-4">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  fname: formData.fname,
                  lname: formData.lname,
                  aadhaar: formData.aadhaar.number,
                  pan: formData.pan.number,
                  phone: formData.phone,
                  altPhone: formData.altPhone,
                  email: formData.email,
                  remarks: formData.remarks,
                  status: formData.status,
                  curAddress1: formData.currentAddress.address1,
                  curAddress2: formData.currentAddress.address2,
                  curcityId: formData.currentAddress.cityId,
                  curStateId: formData.currentAddress.stateId,
                  curCountryId: formData.currentAddress.countryId,
                  curPostalCode: formData.currentAddress.postalCode,
                  permAddress1: formData.permAddress.address1,
                  permAddress2: formData.permAddress.address2,
                  permcityId: formData.permAddress.cityId,
                  permStateId: formData.permAddress.stateId,
                  permCountryId: formData.permAddress.countryId,
                  permPostalCode: formData.permAddress.postalCode,
                  motherName: formData.motherName,
                  fatherName: formData.fatherName,
                  maritalStatus: formData.maritalStatus,
                  educationStream: formData.education.stream,
                  educationDegree: formData.education.degree,
                  noOfYearsLeavingInCurAddress:
                    formData.noOfYearsLeavingInCurAddress,
                  dependents: formData.dependents,
                }}
                validationSchema={Yup.object().shape({
                  fname: Yup.string().required("Please Enter Dealer Name"),
                  email: Yup.string()
                    .email("Please enter a valid Email")
                    .required("Please enter a valid Email Address"),
                  phone: Yup.string()
                    .matches(phoneRegExp, "Please enter a valid Phone Number")
                    .required("Please enter a valid Phone Number"),
                })}
                onSubmit={values => {
                  //props.editProfile(values);
                }}
              >
                {({ errors, status, touched }) => (
                  <Form className="form-horizontal">
                    <Row md={3} lg={3} sm={1} xs={1}>
                      <Col>
                        <Label for="ame" className="form-label">
                          First Name *
                        </Label>
                        <Field
                          name="fname"
                          type="text"
                          placeholder="First Name"
                          values={formData.fname}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          className={
                            "form-control" +
                            (errors.name && touched.name ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="fname"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col>
                        <Label for="ame" className="form-label">
                          Last Name
                        </Label>
                        <Field
                          name="lname"
                          type="text"
                          placeholder="Last Name"
                          values={formData.lname}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          className={
                            "form-control" +
                            (errors.name && touched.name ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="lname"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col>
                        <Label for="ame" className="form-label">
                          {`Father's Name`}
                        </Label>
                        <Field
                          name="fatherName"
                          type="text"
                          placeholder="Father's Name"
                          values={formData.fatherName}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          className={
                            "form-control" +
                            (errors.fatherName && touched.fatherName
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="fatherName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Mother Name *
                        </Label>
                        <Field
                          name="motherName"
                          type="text"
                          placeholder="Mother Name"
                          values={formData.motherName}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          className={
                            "form-control" +
                            (errors.motherName && touched.motherName
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="motherName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Email *
                        </Label>
                        <Field
                          name="email"
                          type="email"
                          maxLength="100"
                          values={formData.email}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          placeholder="Email"
                          className={
                            "form-control" +
                            (errors.email && touched.email ? " is-invalid" : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Phone No. *
                        </Label>
                        <Field
                          name="phone"
                          type="text"
                          maxLength="12"
                          values={formData.phone}
                          placeholder="Phone No."
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          className={
                            "form-control" +
                            (errors.phone && touched.phone ? " is-invalid" : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Alternate Phone No.
                        </Label>
                        <Field
                          name="altPhone"
                          type="text"
                          maxLength="12"
                          values={formData.altPhone}
                          placeholder="Alternate Phone No."
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          className={
                            "form-control" +
                            (errors.altPhone && touched.altPhone
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Aadhaar Number *
                        </Label>
                        <Field
                          name="aadhaar"
                          type="text"
                          maxLength="14"
                          values={formData.aadhaar.number}
                          placeholder="Aadhaar Number"
                          disabled={
                            props.editDisabled || props.aadhaarNo.length > 11
                          }
                          onChange={e => {
                            handleChange("number", e.target.value, "aadhaar");
                          }}
                          className={
                            "form-control" +
                            (errors.aadhaar && touched.aadhaar
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Pan Number *
                        </Label>
                        <Field
                          name="pan"
                          type="text"
                          maxLength="12"
                          values={formData.pan.number}
                          placeholder="Pan Number"
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange("number", e.target.value, "pan");
                          }}
                          className={
                            "form-control" +
                            (errors.pan && touched.pan ? " is-invalid" : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Marital Status *
                        </Label>
                        <Field
                          as="select"
                          name="maritalStatus"
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          values={formData.maritalStatus}
                          disabled={props.editDisabled}
                          className={
                            "form-control" +
                            (errors.maritalStatus && touched.maritalStatus
                              ? " is-invalid"
                              : "")
                          }
                        >
                          <option value="">--Select Marital Status-- </option>
                          {lockeupData["marital-status"]
                            ? lockeupData["marital-status"].map((v, i) => {
                                return (
                                  <option
                                    key={`marital-status-${i}`}
                                    value={v.id}
                                  >
                                    {v.label}
                                  </option>
                                );
                              })
                            : ""}
                        </Field>
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Higher Education Degree
                        </Label>
                        <Field
                          as="select"
                          name="educationDegree"
                          onChange={e => {
                            handleChange("degree", e.target.value, "education");
                          }}
                          values={formData.education.degree}
                          disabled={props.editDisabled}
                          className={
                            "form-control" +
                            (errors.educationDegree && touched.educationDegree
                              ? " is-invalid"
                              : "")
                          }
                        >
                          <option value="">--Select Degree--</option>
                          {lockeupData["education"]
                            ? lockeupData["education"].map((v, i) => {
                                return (
                                  <option key={`education-${i}`} value={v.id}>
                                    {v.label}
                                  </option>
                                );
                              })
                            : ""}
                        </Field>
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Education Stream
                        </Label>
                        <Field
                          name="educationStream"
                          type="text"
                          maxLength="100"
                          values={formData.education.stream}
                          placeholder="Education Stream"
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange("stream", e.target.value, "education");
                          }}
                          className={
                            "form-control" +
                            (errors.educationStream && touched.educationStream
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          No of Dependents *
                        </Label>
                        <Field
                          name="dependents"
                          type="number"
                          maxLength="3"
                          values={formData.dependents}
                          placeholder="No of Dependents"
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          className={
                            "form-control" +
                            (errors.dependents && touched.dependents
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </Col>
                    </Row>
                    <h5
                      style={{ borderBottom: "1px solid #ccc" }}
                      className="mt-4 mb-2"
                    >
                      Current Address Details
                    </h5>
                    <Row md={3} lg={3} sm={1} xs={1}>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Address Line 1 *
                        </Label>
                        <Field
                          name="curAddress1"
                          type="text"
                          placeholder="Address Line 1 "
                          values={formData.currentAddress.address1}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(
                              "address1",
                              e.target.value,
                              "currentAddress"
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.curAddress1 && touched.curAddress1
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="curAddress1"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Address Line 2 *
                        </Label>
                        <Field
                          name="curAddress2"
                          type="text"
                          placeholder="Address Line 2 "
                          values={formData.currentAddress.address2}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(
                              "address2",
                              e.target.value,
                              "currentAddress"
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.curAddress2 && touched.curAddress2
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="curAddress2"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Country *
                        </Label>
                        <Select
                          value={
                            formData.currentAddress.countryId
                              ? countryList[
                                  countryList.findIndex(
                                    a =>
                                      a.value ===
                                      formData.currentAddress.countryId
                                  )
                                ]
                              : null
                          }
                          options={countryList}
                          onChange={e => {
                            handleChange(
                              "countryId",
                              e.value,
                              "currentAddress"
                            );
                          }}
                          classNamePrefix="select2-selection"
                          name="curCountryId"
                          isDisabled={props.editDisabled}
                        />
                        <ErrorMessage
                          name="curCountryId"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          State *
                        </Label>
                        <Select
                          value={
                            formData.currentAddress.stateId
                              ? curStateList[
                                  curStateList.findIndex(
                                    a =>
                                      a.value ===
                                      formData.currentAddress.stateId
                                  )
                                ]
                              : null
                          }
                          options={curStateList}
                          onChange={e => {
                            handleChange("stateId", e.value, "currentAddress");
                          }}
                          classNamePrefix="select2-selection"
                          name="curStateId"
                          isDisabled={props.editDisabled}
                        />
                        <ErrorMessage
                          name="curStateId"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          City *
                        </Label>
                        <Select
                          value={
                            formData.currentAddress.cityId
                              ? curCityList[
                                  curCityList.findIndex(
                                    a =>
                                      a.value === formData.currentAddress.cityId
                                  )
                                ]
                              : null
                          }
                          options={curCityList}
                          onChange={e => {
                            handleChange("cityId", e.value, "currentAddress");
                          }}
                          classNamePrefix="select2-selection"
                          name="curCityId"
                          isDisabled={props.editDisabled}
                        />
                        <ErrorMessage
                          name="curCityId"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          PostaL Code *
                        </Label>
                        <Field
                          type="number"
                          name="postalCode"
                          maxLength="6"
                          disabled={props.editDisabled}
                          placeholder={"Postal Code"}
                          values={formData.currentAddress.postalCode}
                          onChange={e => {
                            handleChange(
                              "postalCode",
                              e.target.value,
                              "currentAddress"
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.curPostalCode && touched.curPostalCode
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="curPostalCode"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          No of years at Current address *
                        </Label>
                        <Field
                          name="noOfYearsLeavingInCurAddress"
                          type="number"
                          placeholder="No of years at Current address"
                          values={formData.noOfYearsLeavingInCurAddress}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(
                              "noOfYearsLeavingInCurAddress",
                              e.target.value
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.noOfYearsLeavingInCurAddress &&
                            touched.noOfYearsLeavingInCurAddress
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="noOfYearsLeavingInCurAddress"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                    <h5
                      style={{ borderBottom: "1px solid #ccc" }}
                      className="mt-4 mb-2"
                    >
                      Permanent Address Details
                    </h5>
                    <p className="text-center">
                      <Label for="same-as-current" className="form-label">
                        Same as Current{" "}
                      </Label>
                      <input
                        type="checkbox"
                        id="same-as-current"
                        style={{ marginLeft: "20px" }}
                      />
                    </p>
                    <Row md={3} lg={3} sm={1} xs={1}>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Address Line 1 *
                        </Label>
                        <Field
                          name="permAddress1"
                          type="text"
                          placeholder="Address Line 1 "
                          values={formData.permAddress.address1}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(
                              "address1",
                              e.target.value,
                              "permAddress"
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.permAddress1 && touched.permAddress1
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="permAddress1"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Address Line 2
                        </Label>
                        <Field
                          name="permAddress2"
                          type="text"
                          placeholder="Address Line 2 "
                          values={formData.permAddress.address2}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(
                              "address2",
                              e.target.value,
                              "permAddress"
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.permAddress2 && touched.permAddress2
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="permAddress2"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Country *
                        </Label>
                        <Select
                          value={
                            formData.permAddress.countryId
                              ? countryList[
                                  countryList.findIndex(
                                    a =>
                                      a.value === formData.permAddress.countryId
                                  )
                                ]
                              : null
                          }
                          options={countryList}
                          onChange={e => {
                            handleChange("countryId", e.value, "permAddress");
                          }}
                          classNamePrefix="select2-selection"
                          name="permCountryId"
                          isDisabled={props.editDisabled}
                        />
                        <ErrorMessage
                          name="permCountryId"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          State *
                        </Label>
                        <Select
                          value={
                            formData.permAddress.stateId
                              ? stateList[
                                  stateList.findIndex(
                                    a =>
                                      a.value === formData.permAddress.stateId
                                  )
                                ]
                              : null
                          }
                          options={stateList}
                          onChange={e => {
                            handleChange("stateId", e.value, "permAddress");
                          }}
                          classNamePrefix="select2-selection"
                          name="permStateId"
                          isDisabled={props.editDisabled}
                        />
                        <ErrorMessage
                          name="permStateId"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          City *
                        </Label>
                        <Select
                          value={
                            formData.permAddress.cityId
                              ? cityList[
                                  cityList.findIndex(
                                    a => a.value === formData.permAddress.cityId
                                  )
                                ]
                              : null
                          }
                          options={cityList}
                          onChange={e => {
                            handleChange("cityId", e.value, "permAddress");
                          }}
                          classNamePrefix="select2-selection"
                          name="permCityId"
                          isDisabled={props.editDisabled}
                        />
                        <ErrorMessage
                          name="permCityId"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          PostaL Code *
                        </Label>
                        <Field
                          type="number"
                          name="permPostalCode"
                          maxLength="6"
                          disabled={props.editDisabled}
                          placeholder={"Postal Code "}
                          values={formData.permAddress.postalCode}
                          onChange={e => {
                            handleChange(
                              "postalCode",
                              e.target.value,
                              "permAddress"
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.permPostalCode && touched.permPostalCode
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="permPostalCode"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                    <Row md={1} lg={1} sm={1} xs={1}>
                      <Col className="pt-1 mt-4">
                        <Label for="ame" className="form-label">
                          Remarks
                        </Label>
                        <Field
                          as="textarea"
                          name="remarks"
                          maxLength="200"
                          placeholder={"Remarks "}
                          values={formData.remarks}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          className={
                            "form-control" +
                            (errors.remarks && touched.remarks
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="remarks"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                    {!props.editDisabled ? (
                      <Row md={1}>
                        <Col className="text-center pt-2">
                          <Button
                            disabled={props.editDisabled}
                            variant="success"
                            className="btn btn-success"
                            onClick={handleFormSubmit}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default IsLoadingHOC(Person, "Wait .....");
